<template>
  <div>
    <AppTable
      :rows="rows"
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: kycConfig.limit,
        count: kycConfig.count,
        page: kycConfig.page,
      }"
      :is-pagination="false"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #default="{ column, row, formattedRow }">
        <span
          v-if="column.field === 'kycLevel'"
          class="flex-center-align"
        >
          <b-badge
            :variant="row.kycLeveL.theme"
            class="text-white"
            pill
          >
            {{ row.kycLeveL.text }}
          </b-badge>
        </span>

        <span
          v-else-if="column.field === 'type'"
          class="flex-center-align"
        >
          <b-badge
            :variant="row.type.theme"
            class="text-white"
          >
            {{ row.type.text }}
          </b-badge>
        </span>

        <span
          v-else-if="column.field === 'action'"
          class="d-flex"
        >
          <b-button
            class="mt-auto text-white ml-1"
            type="button"
            variant="primary"
            @click="edit(row)"
          >
            <span class="text-white"> Edit </span>
          </b-button>
        </span>

        <!-- default render cell -->
        <span
          v-else
          class="flex-center-align"
        >
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BBadge, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

import AppTable from '@/components/AppTable.vue'

import columns from './config/tableConfig'

export default {
  name: 'WalletLimitsKycView',
  components: {
    AppTable,
    BButton,
    BBadge,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError],

  data() {
    return {
      columns,
    }
  },

  computed: {
    ...mapGetters({
      kycConfig: 'kycConfig/kycConfig',
    }),

    rows() {
      return this.transformKYCData(this.kycConfig.value || [])
    },
  },

  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchKYCConfig: 'kycConfig/fetchKYCConfig',
    }),

    async initState() {
      const query = this.createQueryConfig({ limit: undefined })

      this.getKYCConfig(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.getKYCConfig(query)
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.getKYCConfig(query)
    },

    createQueryConfig(config) {
      return {
        page: this.kycConfig.page || undefined,
        limit: this.kycConfig.limit || undefined,

        ...config,
      }
    },

    getKYCConfig(query) {
      return this.waitRequest(() => this.fetchKYCConfig(query).catch(this.checkErrors))
    },

    transformKYCData(rows) {
      return rows.map(row => {
        const isCefi = row.type === 'cefi'

        const type = {
          text: row.type,
          theme: isCefi ? 'primary' : 'info',
        }

        const kycLevelTheme = {
          basic: 'light-primary',
          extended: 'light-info',
          advance: 'light-warning',
        }

        const kycLeveL = {
          text: row.kycLeveL,
          theme: kycLevelTheme?.[row.kycLeveL] ?? kycLevelTheme.basic,
        }

        const dayLimitIn = isCefi ? '-' : row.dayLimitIn
        const monthLimitIn = isCefi ? '-' : row.monthLimitIn

        return {
          ...row,
          type,
          kycLeveL,
          monthLimitIn,
          dayLimitIn,
        }
      })
    },

    edit(row) {
      this.$router.push(`/kyc-config/edit/${row.id}`)
    },
  },
}
</script>
