export default [
  {
    label: 'KYC LeveL',
    field: 'kycLevel',
  },
  {
    label: 'Type',
    field: 'type',
  },
  {
    label: 'Day Limit In',
    field: 'dayLimitIn',
  },
  {
    label: 'Day Limit Out',
    field: 'dayLimitOut',
  },
  {
    label: 'Month Limit In',
    field: 'monthLimitIn',
  },
  {
    label: 'Month Limit Out',
    field: 'monthLimitOut',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
