var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppTable',{attrs:{"rows":_vm.rows,"columns":_vm.columns,"is-loading":_vm.requestInProgress,"pagination":{
      limit: _vm.kycConfig.limit,
      count: _vm.kycConfig.count,
      page: _vm.kycConfig.page,
    },"is-pagination":false},on:{"change-limit":_vm.changePageLimit,"change-page":_vm.changePage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var column = ref.column;
    var row = ref.row;
    var formattedRow = ref.formattedRow;
return [(column.field === 'kycLevel')?_c('span',{staticClass:"flex-center-align"},[_c('b-badge',{staticClass:"text-white",attrs:{"variant":row.kycLeveL.theme,"pill":""}},[_vm._v(" "+_vm._s(row.kycLeveL.text)+" ")])],1):(column.field === 'type')?_c('span',{staticClass:"flex-center-align"},[_c('b-badge',{staticClass:"text-white",attrs:{"variant":row.type.theme}},[_vm._v(" "+_vm._s(row.type.text)+" ")])],1):(column.field === 'action')?_c('span',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mt-auto text-white ml-1",attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.edit(row)}}},[_c('span',{staticClass:"text-white"},[_vm._v(" Edit ")])])],1):_c('span',{staticClass:"flex-center-align"},[_vm._v(" "+_vm._s(formattedRow[column.field])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }